@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:300');

#intro {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  background: $matrix-black;
  font-weight: 400;
  color: $matrix-dark-green;
  padding: 0rem 5.6rem;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  font-family: 'Roboto Mono';

  /* background: url("/src/assets/[your-image].png");
  background-position: center;
  background-size: cover; */

  @include respond(phone) {
    padding: 0rem $default-font-size;
  }

  .intro-title {
    font-size: 5.6rem;
    font-weight: 700;
    margin-bottom: 3.2rem;
    text-align: left;

    @include respond(tab-land) {
      font-size: 5rem;
    }
    @include respond(tab-port) {
      font-size: 5rem;
      text-align: center;
    }
    @include respond(phone) {
      font-size: 3.8rem;
      line-height: 1.5;
    }
    @include respond(phone-xs) {
      font-size: 2.8rem;
    }

    .intro-secondary-text {
      font-weight: 500;
      font-size: 4.2rem;

      @include respond(tab-land) {
        font-size: 4rem;
      }
      @include respond(tab-port) {
        font-size: 4rem;
        text-align: center;
      }
      @include respond(phone) {
        font-size: 3rem;
        line-height: 1.5;
      }
      @include respond(phone-xs) {
        font-size: 2.6rem;
      }
    }
  }

  .intro-cta {
    display: flex;

    @include respond(tab-port) {
      justify-content: center;
    }

    & a {
      font-size: 2.4rem;
      font-weight: 500;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }
}
