// COLORS
// $primary-color: #02aab0;
// $secondary-color: #00cdac;

$primary-color: #12343b;
$secondary-color: #2d545e;

$white-color: #fff;
$secondary-white-color: #f5f5f5;

$dark-grey: #333333;

$dark-blue-text: #545454; // For Headings

$matrix-black: #0D0208;
$matrix-dark-green: #008F11;
$matrix-light-green: #00FF41;

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;
