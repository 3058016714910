@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

/* Call to Action Button */
.cta-btn {
  display: inline-block;
  position: relative;
  padding: 0.8rem $default-font-size;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }
}

/* Intro Style */
.cta-btn--intro {
  background-image: linear-gradient(135deg, $primary-color 0%, $matrix-dark-green 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(135deg, $matrix-light-green 0%, $matrix-dark-green 100%);
  border-image-slice: 1;
  cursor: pointer;
  font-family: 'Roboto Mono';
  // Responsive Button intro style
  @include respond(phone) {
    background-image: none;
    border: 2px solid $matrix-dark-green;
    -webkit-text-fill-color: $secondary-color;
  }

  &::after {
    background-image: linear-gradient(135deg, $primary-color 0%, $secondary-color 100%);
    // Responsive Button intro style
    @include respond(phone) {
      background-image: none;
    }
  }

  &:hover {
    -webkit-text-fill-color: $white-color;
    text-decoration: none;
    // Responsive Button hover intro style
    @include respond(phone) {
      -webkit-text-fill-color: $secondary-color;
    }

    &::after {
      width: 100%;
    }
  }
}

/* Resume Style */
.cta-btn--resume {
  color: $white-color;
  border: 2px solid $white-color;

  &::after {
    background: $white-color;
  }

  &:hover {
    color: $secondary-color;
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
}

/* Arrow Button */
.up i {
  color: #272727;
}
